<template>
    <div class="relative">
      <div class="p-0 border-0 squeezed" v-if="true || fieldStyle == 'squeezed'">
        <label
          :class="`w-50 absolute text-xs ml-3 mt-1 t-2 font-bold text-slate-400 truncate pr-2 uppercase z-20`"
        >{{ placeholder }}</label>
        <Datepicker 
          :disabled="disabled" 
          :timezone="'UTC'" 
          :model-value="internalValue" 
          @update:model-value="updateValue" 
          format="dd/MM/yyyy"  
          :enable-time-picker="false" 
          autoApply 
          utc="preserve"
        />
      </div>
    </div>
  </template>
  
  <script>
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  
  export default {
    components: { Datepicker },
    props: ['modelValue', 'placeholder', 'labelWidth', 'fieldStyle', 'disabled'],
    data() {
      return {
        internalValue: this.modelValue // Lokal kopi af modelValue for at håndtere tovejskommunikationen
      };
    },
    watch: {
      modelValue(newValue) {
        this.internalValue = newValue; // Opdater intern værdi, når prop ændres
      }
    },
    methods: {
      updateValue(value) {
        this.internalValue = value;
        this.$emit('update:modelValue', value); // Emit event for at opdatere modelValue i parent-komponenten
      }
    },
    emits: ['update:modelValue']
  };
  </script>
  
  <style lang="css">
  #name {
    padding: 0;
    border: 0;
  }
  .dp__input_icon {
    margin-left: 0px !important;
    margin-top: 5px !important;
  }
  .classic #name {
    border: 0;
  }
  .dp__input {
    padding: unset !important;
    padding-top: 10px !important;
    padding-left: 35px !important;
    padding-bottom: 0 !important;
    line-height: unset !important;
  }
  .classic .dp__input_reg {
    height: 50px;
  }
  .squeezed .dp__input_reg {
    height: 53px;
  }
  </style>
  